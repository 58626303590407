import { useEffect, useState } from 'react'

interface ScrollData {
  direction: number
  ty: number
}

function useScroll(): ScrollData {
  const [scrollData, setScrollData] = useState<ScrollData>({ direction: 0, ty: 0 })

  useEffect(() => {
    const onScroll = () => {
      setScrollData({
        direction: Math.sign(window.pageYOffset - scrollData.ty),
        ty: window.pageYOffset,
      })
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollData])

  return scrollData
}

export default useScroll
