import { theme as baseTheme, extendTheme } from '@chakra-ui/react'

import { Avatar } from './avatar'
import { Button } from './button'
import { Container } from './container'
import { Heading } from './heading'
import { Text } from './text'

// Breakpoints
// https://chakra-ui.com/docs/features/responsive-styles
// Chakra breakpoints are:
// sm: "30em" = 480px
// md: "48em" = 768px
// lg: "62em" = 992px
// xl: "80em" = 1280px
// "2xl": "96em" = 1536px

// Making sure chakra base sizes are not present
// eslint-disable-next-line @typescript-eslint/no-explicit-any
baseTheme.components.Heading.sizes = undefined as any

export const theme = extendTheme({
  components: {
    Avatar,
    Button,
    Container,
    Heading,
    Text,
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
      },
    },
  },
  shadows: {
    md: '0px 0px 16px rgba(0, 0, 0, 0.08)',
  },
  fonts: {
    heading: `SingolareLayers, ${baseTheme.fonts.heading}`,
    body: `Lato, ${baseTheme.fonts.body}`,
  },
  // Thes font size should not be used
  // Use the component's sizes instead
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  lineHeights: {
    md: '1,4rem',
  },
  colors: {
    light_border: '#F6F5F0',
    main_grey: '#2A2A2A99',
    light_gray: '#F6F5F1',
    light_grey: 'rgba(0, 0, 0, 0.3)',
    main_gray_lighten: '#757575',
    blue: '#00FFE1',
    dark_green: '#014129',
    green: '#014129',
    light_green: '#29C48B',
    orange: '#FF6C4A',
    pink: '#FFFBF6',
    white: '#fff',
    miimosa: '#003F2C',
    blue_miimosa: '#638DFF',
    black: '#000',
    black_text: '#1E1E1E',
    radio: {
      500: '#00B4D5',
    },
    checkbox: {
      200: 'red',
      500: '#014129',
    },
    input: {
      500: '#00B4D5',
    },
  },
})
