export const getProjectThematics = () => {
  return [
    {
      slug: 'enr',
      title: 'Energies Renouvelables',
    },
    {
      slug: 'coop',
      title: 'Coopératives et entreprises',
    },
    {
      slug: 'craft',
      title: 'Exploitations et artisanat',
    },
    {
      slug: 'startup',
      title: 'Startups',
    },
  ]
}

export const getProjectThematicsIdBySlug = {
  enr: 73,
  coop: 74,
  craft: 75,
  startup: 76,
}
