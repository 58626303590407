import path from 'path'

import type { CountryCode } from '@miimosa/common/types'

import usePlatform from './use_platform'

const containsL = /\?.*&?l=/

function appendLang(p: string, lang: string): string {
  // This is sort of gross but we have no way to know if p is a full url or not
  if (containsL.test(p)) return p
  return p + (p.includes('?') ? '&' : '?') + 'l=' + lang
}

function ignorePlatform(p: string): boolean {
  const prefixes = ['/fr', '/be', 'http']
  for (const prefix of prefixes) {
    if (p.startsWith(prefix)) {
      return true
    }
  }
  return false
}

export function toV2Link(p: string, platform: CountryCode, lang: string): string {
  if (ignorePlatform(p)) {
    // In some cases we get a full url (e-g data from projects api)
    // We just append the lang
    return appendLang(p, lang)
  }
  // can't use URL because not really a URL
  // We are not forcing a platform if it is not already present, this is because
  // some generated links already have a set platform
  const out = path.join(platform, p)
  return '/' + appendLang(out, lang)
}

export default function useV2Link(p: string): string {
  const { platform, lang } = usePlatform()
  return toV2Link(p, platform, lang)
}
