interface Props {
  regionalCode: string
}

export const getFRRegionNameAndIconByCode = ({ regionalCode }: Props) => {
  return regionMapping['fr'][regionalCode as keyof typeof regionMapping['fr']]
}

export const getBERegionNameAndIconByCode = ({ regionalCode }: Props) => {
  return regionMapping['be'][regionalCode as keyof typeof regionMapping['be']]
}

const regionMapping = {
  fr: {
    '84': { name: 'Auvergne-Rhône-Alpes', iconName: 'AuvergneRhoneAlpes' },
    '27': { name: 'Bourgogne-Franche-Comté', iconName: 'BourgogneFrancheComte' },
    '53': { name: 'Bretagne', iconName: 'Bretagne' },
    '24': { name: 'Centre-Val de Loire', iconName: 'CentreValDeLoire' },
    '94': { name: 'Corse', iconName: 'Corse' },
    '44': { name: 'Grand Est', iconName: 'GrandEst' },
    '32': { name: 'Hauts-de-France', iconName: 'HautsDeFrance' },
    '11': { name: 'Île-de-France', iconName: 'IleDeFrance' },
    '28': { name: 'Normandie', iconName: 'Normandie' },
    '75': { name: 'Nouvelle-Aquitaine', iconName: 'NouvelleAquitaine' },
    '76': { name: 'Occitanie', iconName: 'Occitanie' },
    '52': { name: 'Pays de la Loire', iconName: 'PaysDeLaLoire' },
    '93': { name: "Provence-Alpes-Côte d'Azur", iconName: 'ProvencesAlpesCoteDazur' },
    '01': { name: 'Guadeloupe', iconName: 'OutreMer' },
    '02': { name: 'Martinique', iconName: 'OutreMer' },
    '03': { name: 'Guyane', iconName: 'OutreMer' },
    '04': { name: 'La Réunion', iconName: 'OutreMer' },
    '06': { name: 'Mayotte', iconName: 'OutreMer' },
  },
  be: {
    //   "VB","WV"
    '14': { name: 'Anvers', iconName: 'Anvers' },
    '15': { name: 'Hainaut', iconName: 'Hainaut' }, //HT
    '16': { name: 'Limbourg', iconName: 'Limbourg' },
    '17': { name: 'Luxembourg', iconName: 'Luxembourg' }, //LX
    '18': { name: 'Namur', iconName: 'Namur' }, //NA
    '19': { name: 'Liege', iconName: 'Liege' }, //LG
    '20': { name: 'Flandre occidentale', iconName: 'FlandreOccidentale' },
    '21': { name: 'Bruxelles', iconName: 'Bruxelles' }, //BR
    '22': { name: 'Flandre orientale', iconName: 'FlandreOrientale' },
    '23': { name: 'Brabant Flamant', iconName: 'BrabantFlamant' },
    '24': { name: 'Brabant Wallon', iconName: 'BrabantWallon' }, //BW
  },
}
