import { Container, Flex, Text } from '@chakra-ui/react'
import NextLink from 'next/link'

import { s3ImageURL } from '@miimosa/common'

import Image from '@components/Image'

const Nav = () => {
  return (
    <Container
      variant="full"
      as="nav"
      w="full"
      flexDirection="row"
      h="90px"
      bg="white"
      alignItems="center"
      px={{ base: 50, lg: 120 }}
      borderBottom="1px solid rgba(0,0,0,0.1)"
      position="sticky"
      top="0"
      left="0"
      right="0"
      zIndex="1000"
    >
      <Flex direction="row" columnGap={12} h="full" alignItems="center">
        <Flex w="38px" h="28px" position="relative">
          <Image src={s3ImageURL('m6-pub-logo.png')} fill style={{ objectFit: 'cover' }} alt="m6 logo" />
        </Flex>
        <NextLink href={`/`}>
          <Flex w="124px" h="21px" position="relative">
            <Image
              src={s3ImageURL('miimosa-logo-green-transparent.png')}
              fill
              style={{ objectFit: 'cover' }}
              alt="miimosa logo"
              priority
            />
          </Flex>
        </NextLink>
      </Flex>
      <Flex direction="row" columnGap={{ base: 4, md: 8, lg: 10, xl: 14 }} mx="auto">
        {/* <NextLink href={'/#cta'} scroll={false}>
          <Text>Appel à projets</Text>
        </NextLink> */}
        <NextLink href={'/#partners'} scroll={false}>
          <Text>Les partenaires</Text>
        </NextLink>
        <NextLink href={'/laureats'}>
          <Text>Les lauréats</Text>
        </NextLink>
        <NextLink href={'/projects'}>
          <Text>Les projets</Text>
        </NextLink>
        <NextLink href={'/#faq'} scroll={false}>
          <Text>FAQ</Text>
        </NextLink>
      </Flex>
      {/* <NextLink href={'/#form'} scroll={false}>
        <Button variant="primary" size="md">
          Candidater
        </Button>
      </NextLink> */}
    </Container>
  )
}

export default Nav
