import { useRouter } from 'next/router'
import { useCallback } from 'react'

import usePersistedState from './use_persisted_state'
import { V2_USER } from './use_v2_user'

function useLogout(): () => void {
  const router = useRouter()

  const setUser = usePersistedState(V2_USER, null)[1]

  return useCallback(async () => {
    setUser(null)
    await fetch('/nextapi/users/logout', { method: 'DELETE', credentials: 'same-origin' })
    router.push('/')
  }, [router, setUser])
}

export default useLogout
