import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Burger',
  viewBox: '0 0 25 18',
  path: (
    <>
      <line x1="1" y1="1" x2="24" y2="1" stroke="#003F2C" strokeWidth="2" strokeLinecap="round" />
      <line x1="1" y1="9" x2="24" y2="9" stroke="#003F2C" strokeWidth="2" strokeLinecap="round" />
      <line x1="1" y1="17" x2="24" y2="17" stroke="#003F2C" strokeWidth="2" strokeLinecap="round" />
    </>
  ),
})
