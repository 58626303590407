import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Flex,
  DrawerBody,
  Divider,
  useDisclosure,
  Container,
  Text,
} from '@chakra-ui/react'
import NextLink from 'next/link'

import { s3ImageURL } from '@miimosa/common'

import { Burger, Cross } from '@components/icons'
import Image from '@components/Image'

const NavMobile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Container
      variant="full"
      as="nav"
      w="full"
      flexDirection="row"
      h="90px"
      bg="white"
      alignItems="center"
      borderBottom="1px solid rgba(0,0,0,0.1)"
      position="sticky"
      top="0"
      left="0"
      right="0"
      zIndex="1000"
    >
      <Flex
        direction="row"
        columnGap={12}
        h="full"
        alignItems="center"
        w="full"
        justifyContent={{ base: 'space-evenly', md: 'normal' }}
      >
        <Flex w="38px" h="28px" position="relative">
          <Image src={s3ImageURL('m6-pub-logo.png')} fill style={{ objectFit: 'cover' }} alt="m6 logo" />
        </Flex>
        <Flex w="124px" h="21px" position="relative">
          <Image
            src={s3ImageURL('miimosa-logo-green-transparent.png')}
            fill
            style={{ objectFit: 'cover' }}
            alt="miimosa logo"
            priority
          />
        </Flex>
        <Burger onClick={onOpen} />
      </Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent w="full" maxW="full">
          <Flex
            direction="row"
            columnGap={12}
            h="91px"
            alignItems="center"
            w="full"
            justifyContent={{ base: 'space-evenly', md: 'normal' }}
            borderBottom="1px solid rgba(0,0,0,0.1)"
          >
            <Flex w="38px" h="28px" position="relative">
              <Image src={s3ImageURL('m6-pub-logo.png')} fill style={{ objectFit: 'cover' }} alt="m6 logo" />
            </Flex>
            <Flex w="124px" h="21px" position="relative">
              <Image
                src={s3ImageURL('miimosa-logo-green-transparent.png')}
                fill
                style={{ objectFit: 'cover' }}
                alt="miimosa logo"
              />
            </Flex>
            <Cross onClick={onClose} />
          </Flex>

          <DrawerBody px={0}>
            <Flex direction="column" rowGap={6} mt={6}>
              {/* <NextLink href={'/#cta'} scroll={false}>
                <Text onClick={onClose} px={6}>
                  Appel à projets
                </Text>
              </NextLink> */}
              {/* <Divider w="full" color="rgba(0,0,0,0.1)" /> */}
              <NextLink href={'/#partners'} scroll={false}>
                <Text onClick={onClose} px={6}>
                  Les partenaires
                </Text>
              </NextLink>
              <Divider w="full" color="rgba(0,0,0,0.1)" />
              <NextLink href={'/laureats'}>
                <Text onClick={onClose} px={6}>
                  Les lauréats
                </Text>
              </NextLink>
              <Divider w="full" color="rgba(0,0,0,0.1)" />
              <NextLink href={'/projects'}>
                <Text onClick={onClose} px={6}>
                  Les projets
                </Text>
              </NextLink>
              <Divider w="full" color="rgba(0,0,0,0.1)" />
              <NextLink href={'/#faq'} scroll={false}>
                <Text onClick={onClose} px={6}>
                  FAQ
                </Text>
              </NextLink>
              {/* <Divider w="full" color="rgba(0,0,0,0.1)" /> */}
              {/* <NextLink href={'/#form'} scroll={false}>
                <Text onClick={onClose} px={6}>
                  Candidater
                </Text>
              </NextLink> */}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Container>
  )
}

export default NavMobile
