import React from 'react'

export interface navBarType {
  [key: string]: {
    link?: string
    key: string
    title: string
    renderComponent: React.ReactElement
    count?: number
    display?: 'flex' | 'none' | 'block'
  }
}
