import { useMountedBreakpoints } from '@miimosa/design-system/lib/hooks'

import { Nav, NavMobile } from '@components/Nav'

const Header = () => {
  const isMobile = useMountedBreakpoints({ base: true, md: false }, false)

  return isMobile ? <NavMobile /> : <Nav />
}

export default Header
