import { useCallback, useEffect, useState } from 'react'

interface Storage {
  setItem(key: string, value: string): void
  getItem(key: string): string | null
  removeItem(key: string): void
}

function getStorage(): Storage | null {
  if (typeof global !== 'undefined' && global.localStorage) {
    return global.localStorage
  }
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage
  }
  if (typeof localStorage !== 'undefined') {
    return localStorage
  }
  return null
}

const defaultStorage = getStorage()

// Use persisted state will do nothing server side
function usePersistedState<T>(key: string, def: T): [T, (val: T) => void] {
  const [val, setVal] = useState(def)

  // Making sure that the value is only updated when mounted
  useEffect(() => {
    const val = defaultStorage?.getItem(key)
    if (val !== null && val !== undefined && val !== 'undefined') {
      setVal(JSON.parse(val) as T)
    }
  }, [key, setVal])

  const setter = useCallback(
    (val: T) => {
      setVal(val)
      if (val !== null && val !== undefined) {
        defaultStorage?.setItem(key, JSON.stringify(val))
      } else {
        defaultStorage?.removeItem(key)
      }
    },
    [key, setVal]
  )

  return [val, setter]
}

export default usePersistedState
