import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Cross',
  viewBox: '0 0 18 18',
  path: (
    <>
      <line x1="1" y1="16.5858" x2="16.5563" y2="1.02944" stroke="#003F2C" strokeWidth="2" strokeLinecap="round" />
      <line x1="1.41421" y1="1" x2="16.9706" y2="16.5563" stroke="#003F2C" strokeWidth="2" strokeLinecap="round" />
    </>
  ),
})
