import '../styles/globals.css'
import '../styles/fonts.css'
import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { register } from 'timeago.js'

import { timeagoFR } from '@miimosa/design-system/lib/i18n/timeago'

import { theme } from '../themes'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'rc-pagination/assets/index.css'

import Layout from '@components/Layout'

function FallbackComponent() {
  return <div>An error has occured</div>
}

// register your locale with timeago
register('fr', timeagoFR)

function MyApp({ Component, pageProps }: AppProps & any) {
  useEffect(() => {
    if (process.env.NODE_ENV == 'production') {
      TagManager.initialize({ gtmId: 'GTM-5N848TR' })
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <ChakraProvider theme={theme}>
        <Layout>{<Component {...pageProps} />}</Layout>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  )
}

export default appWithTranslation(MyApp)
