import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  sm: {
    fontSize: { base: '1.25rem' },
    lineHeight: { base: '1.5rem' },
  },
  md: {
    fontSize: { base: '1.375rem' },
    lineHeight: { base: '1.65rem' },
  },
  lg: {
    fontSize: { base: '1.75rem' },
    lineHeight: { base: '2.1rem' },
    fontWeight: '400',
  },
  xl: {
    fontSize: { base: '2.125rem' },
    lineHeight: { base: '2.55rem' },
  },
  '2xl': {
    fontSize: { base: '3rem', lg: '3.5rem' },
    lineHeight: { base: '3.5rem', lg: '4rem' },
    fontWeight: 700,
  },
}

export const Heading = {
  sizes,
  // The default size and variant values
  defaultProps: {
    size: 'md',
  },
}
