import type { Projects } from '@miimosa/protocol'

import { buildV2ImagePath } from '../../helpers'
import type { FrontPartnerLogoType } from '../../types'

export const v3PartnerLogosToFront = (partnerLogo: Projects.PartnerLogo): FrontPartnerLogoType => {
  return {
    url: buildV2ImagePath({
      kind: 'partner',
      id: partnerLogo.id,
      filename: partnerLogo.image_file_name,
      size: 'thumb',
    }),
  }
}
