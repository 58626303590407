import { useRouter } from 'next/router'

import type { CountryCode, Lang } from '@miimosa/common/types'

export function parseLocale(locale: string | undefined): { platform: CountryCode; lang: Lang } {
  return {
    platform: locale?.endsWith('-BE') ? 'be' : 'fr',
    lang: locale?.startsWith('nl-') ? 'nl' : 'fr',
  }
}

function usePlatform(): { platform: CountryCode; lang: Lang } {
  const { locale } = useRouter()
  return parseLocale(locale)
}

export default usePlatform
