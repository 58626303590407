import { TFunction } from 'next-i18next'

export const getProjectCategories = (t?: TFunction) => {
  return [
    {
      slug: 'alcool',
      ...(t && { title: t('categories.alcool', { ns: 'common' }) }),
    },
    {
      slug: 'apiculture',
      ...(t && { title: t('categories.apiculture', { ns: 'common' }) }),
    },
    {
      slug: 'aquaculture',
      ...(t && { title: t('categories.aquaculture', { ns: 'common' }) }),
    },
    {
      slug: 'elevage',
      ...(t && { title: t('categories.elevage', { ns: 'common' }) }),
    },
    {
      slug: 'horticulture',
      ...(t && { title: t('categories.horticulture', { ns: 'common' }) }),
    },
    {
      slug: 'innovation',
      ...(t && { title: t('categories.innovation', { ns: 'common' }) }),
    },
    {
      slug: 'lait',
      ...(t && { title: t('categories.lait', { ns: 'common' }) }),
    },
    {
      slug: 'nature',
      ...(t && { title: t('categories.nature', { ns: 'common' }) }),
    },
    {
      slug: 'sale',
      ...(t && { title: t('categories.sale', { ns: 'common' }) }),
    },
    {
      slug: 'soft',
      ...(t && { title: t('categories.soft', { ns: 'common' }) }),
    },
    {
      slug: 'sucre',
      ...(t && { title: t('categories.sucre', { ns: 'common' }) }),
    },
    {
      slug: 'viticulture',
      ...(t && { title: t('categories.viticulture', { ns: 'common' }) }),
    },
  ]
}

export const getProjectCategoriesIdBySlug = {
  apiculture: 1,
  aquaculture: 2,
  alcool: 3,
  soft: 4,
  elevage: 5,
  sale: 6,
  sucre: 7,
  horticulture: 8,
  innovation: 9,
  nature: 10,
  lait: 11,
  viticulture: 12,
}

export const categoriesMapping: { [category: string]: string } = {
  alcool: 'Alcool',
  apiculture: 'BeeKeeping',
  aquaculture: 'FishFarming',
  elevage: 'Breeding',
  horticulture: 'Horticulture',
  innovation: 'Innovation',
  lait: 'Milk',
  nature: 'Nature',
  sale: 'Salty',
  soft: 'Soft',
  sucre: 'Sweet',
  viticulture: 'VineGrowing',
}
