import { TFunction } from 'next-i18next'

export const getImpactCategories = (t?: TFunction) => {
  return [
    {
      slug: 'biodiversity',
      imageUrl: 'biodiversity.jpg',
      icon: 'Biodiversity',
      ...(t && { title: t('impacts.categories.bioDiversity', { ns: 'common' }) }),
    },
    {
      slug: 'animal_welfare',
      imageUrl: 'animal_welfare.jpg',
      icon: 'AnimalWelfare',
      ...(t && { title: t('impacts.categories.animalWelfare', { ns: 'common' }) }),
    },
    {
      slug: 'renewable_energy',
      imageUrl: 'renewable_energy.jpg',
      icon: 'RenewableEnergy',
      ...(t && { title: t('impacts.categories.renewableEnergy', { ns: 'common' }) }),
    },
    {
      slug: 'living_standards',
      imageUrl: 'life_quality_evolution.jpg',
      icon: 'LivingStandards',
      ...(t && { title: t('impacts.categories.lifeQualityEvolution', { ns: 'common' }) }),
    },
    {
      slug: 'social_cohesion',
      imageUrl: 'social_cohesion.jpg',
      icon: 'SocialCohesion',
      ...(t && { title: t('impacts.categories.socialCohesion', { ns: 'common' }) }),
    },
    {
      slug: 'waste_reduction',
      imageUrl: 'waste_reduction.jpg',
      icon: 'WasteReduction',
      ...(t && { title: t('impacts.categories.wasteReduction', { ns: 'common' }) }),
    },
    {
      slug: 'food_national_safety',
      imageUrl: 'security_food_sovereignty.jpg',
      icon: 'FoodNationalSafety',
      ...(t && { title: t('impacts.categories.securityFoodSovereignty', { ns: 'common' }) }),
    },
    {
      slug: 'local_development',
      imageUrl: 'dev_territorial.jpg',
      icon: 'LocalDevelopment',
      ...(t && { title: t('impacts.categories.devTerritorial', { ns: 'common' }) }),
    },
    {
      slug: 'natural_resources',
      imageUrl: 'natural_ressources.jpg',
      icon: 'NaturalRessources',
      ...(t && { title: t('impacts.categories.naturalRessources', { ns: 'common' }) }),
    },
    {
      slug: 'humain_health_and_wellbeing',
      imageUrl: 'health_well_being.jpg',
      icon: 'HealthWellBeing',
      ...(t && { title: t('impacts.categories.healthWellBeing', { ns: 'common' }) }),
    },
    {
      slug: 'climate',
      imageUrl: 'climate_change.jpg',
      icon: 'Climat',
      ...(t && { title: t('impacts.categories.climateChange', { ns: 'common' }) }),
    },
  ]
}

export const getProjectImpactsIdBySlug = {
  food_national_safety: 5,
  humain_health_and_wellbeing: 6,
  climate: 7,
  biodiversity: 8,
  natural_resources: 9,
  renewable_energy: 10,
  waste_reduction: 11,
  animal_welfare: 12,
  local_development: 13,
  living_standards: 14,
  social_cohesion: 15,
  soil_regeneration: 25,
  carbone_footprint: 26,
  animal_wellbeing: 27,
  energy_production: 28,
  food_autonomy: 29,
}
