import { Text, Flex, Button, Link } from '@chakra-ui/react'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { IconType } from 'react-icons/lib'

import { s3ImageURL } from '@miimosa/common'

import Image from '@components/Image'

const Footer = () => {
  return (
    <Flex
      direction="column"
      w="full"
      h="auto"
      rowGap={10}
      py={10}
      bgColor="green"
      alignItems="center"
      justifyContent="space-around"
    >
      <Flex direction="column" justifyContent="center" alignItems="center" rowGap={8}>
        <Link href="https://miimosa.com" target="_blank" display="flex" position="relative" w="151px" h="25px">
          <Image
            src={s3ImageURL('miimosa-white-logo.png')}
            fill
            style={{ objectFit: 'cover' }}
            alt="miimosa logo"
            priority={false}
          />
        </Link>
        <Flex direction="row" w="full" justifyContent="center" columnGap={8}>
          <SocialLogo link="https://www.instagram.com/miimosa_fr/?hl=fr" Icon={FaInstagram} />
          <SocialLogo link="https://www.facebook.com/MiiMOSAfr/" Icon={FaFacebookF} />
          <SocialLogo link="https://www.linkedin.com/company/miimosa/" Icon={FaLinkedinIn} />
          <SocialLogo link="https://twitter.com/miimosa_fr" Icon={FaTwitter} />
        </Flex>
        <Button variant="primaryWithWhiteBorder" size="md" as="a" href="https://blog.miimosa.com/" target="_blank">
          <Text size="sm" color="white">
            Le blog
          </Text>
        </Button>
      </Flex>
      <Flex direction="row" columnGap={2} color="rgba(255, 255, 255, 0.5)">
        <Text>© MiiMOSA 2023</Text> |
        <Text
          as="a"
          href="https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/CGU_MiiMOSA_2021_v02.09.2021.pdf#page=24"
          target="_blank"
        >
          Mentions légales
        </Text>
        |
        <Text
          as="a"
          href="https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/CGU_MiiMOSA_2021_v02.09.2021.pdf#page="
          target="_blank"
        >
          CGU
        </Text>
        |
        <Text as="a" href="https://miimosa.com/fr/cookies?l=fr" target="_blank">
          Cookies
        </Text>
      </Flex>
    </Flex>
  )
}

const SocialLogo = ({ link, Icon }: { link: string; Icon: IconType }) => {
  return (
    <Flex as="a" href={link} w="46px" h="46px" justifyContent="center" alignItems="center" target="_blank">
      <Icon color="white" size={25} />
    </Flex>
  )
}

export default Footer
