export const PROJECTS_IMAGES_PATH = 'projects/images' as const
export const USERS_AVATARS_PATH = 'users/avatars' as const
export const REWARDS_IMAGES = 'rewards/images' as const
export const LENDING_DIR_PATH = 'project/collect/lendings/dirs' as const
export const PARTNERS_IMAGES_PATH = 'partner_logos/images' as const
export const ARTICLES_NOTES_IMAGES_PATH = 'articles/documents' as const

const PATH_MAPPING = {
  project: PROJECTS_IMAGES_PATH,
  user: USERS_AVATARS_PATH,
  reward: REWARDS_IMAGES,
  lending: LENDING_DIR_PATH,
  partner: PARTNERS_IMAGES_PATH,
  notes: ARTICLES_NOTES_IMAGES_PATH,
}

export type ImageProps = {
  kind: keyof typeof PATH_MAPPING
  filename: string
  id: number
  size: 'card' | 'full' | 'high' | 'original' | 'thumb'
}

export const buildV2ImagePath = ({ kind, filename, id, size }: ImageProps) => {
  if (filename?.startsWith('https://')) {
    return filename
  }

  return `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}/${process.env.NEXT_PUBLIC_ENV}/${
    PATH_MAPPING[kind as keyof typeof PATH_MAPPING]
  }/${splitProjectId(id)}/${size}/${filename}`
}

const splitProjectId = (id: number) => {
  const total = String(id).padStart(9, '0')
  const formatted = total.match(/\d{1,3}/g)?.join('/')
  return formatted
}
