import type { Common, Projects } from '@miimosa/protocol'

import { buildV2ImagePath } from '../../helpers'
import type { FrontRewardType, FrontShippingModeType } from '../../types'

export const v3RewardToFront = ({
  available_quantity,
  image_file_name,
  delivery_at,
  shipping_mode,
  shipping_details,
  taken_quantity,
  is_available,
  ...rest
}: Projects.Reward): FrontRewardType => {
  return {
    availableQuantity: available_quantity,
    imageFileName: image_file_name
      ? buildV2ImagePath({
          kind: 'reward',
          filename: image_file_name || '',
          id: rest.id,
          size: 'original',
        })
      : null,
    deliveryAt: delivery_at,
    shippingMode: v3ShippingModeToFront(shipping_mode),
    shippingDetails: shipping_details,
    takenQuantity: taken_quantity,
    isAvailable: is_available,
    ...rest,
  }
}

export const v3ShippingModeToFront = (shippingMode: Common.ShippingMode): FrontShippingModeType => {
  switch (shippingMode) {
    case 'SHIPPING_MODE_LOCAL_SHIPPING':
      return 'local_shipping'
    case 'SHIPPING_MODE_NATIONAL_SHIPPING':
      return 'national_shipping'
    case 'SHIPPING_MODE_PICK_UP':
      return 'pick_up'
    case 'SHIPPING_MODE_NO_SHIPPING':
    case 'SHIPPING_MODE_UNSPECIFIED':
    case 'UNRECOGNIZED':
      return 'no_shipping'
  }
}
