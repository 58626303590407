const firstSubdomainRegexp = new RegExp('^(?:https?://)?(?:www.)?([^:|.]+)', 'is')
const rootDomainRegexp = new RegExp('([^.:]+\\.[^.:]+)(?::\\d+)?$')

export const ALLOWED_DOMAINS = { innocent: true }

export type DOMAIN_TYPE = keyof typeof ALLOWED_DOMAINS

const domainMapping: Record<string, keyof typeof ALLOWED_DOMAINS> = {
  'lagrandeclasseverte-innocent': 'innocent',
}

export const getDomainFromHost = (host: string | null) => {
  const res = host ? firstSubdomainRegexp.exec(host) : undefined
  if (!res) return undefined

  const domain = res[1]
  return domainMapping[domain] ?? domain
}

export const getRootDomainFromHost = (host: string | undefined) => {
  const res = host ? rootDomainRegexp.exec(host) : undefined
  if (!res) return undefined

  return res[1]
}

const sitePathRegex = new RegExp('^/_sites/([^/]+)', 'is')

export const getDomainFromPath = (path: string) => {
  const domainName = sitePathRegex.exec(path)
  return domainName ? domainName[1] : undefined
}

const GTM_TAG_MAPPING: Record<DOMAIN_TYPE, string> = {
  innocent: 'GTM-T9Z5WK8',
}

export function getTagID(domain: DOMAIN_TYPE) {
  if (process.env.NODE_ENV !== 'production') {
    return undefined
  }
  return GTM_TAG_MAPPING[domain] ?? (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS || process.env.GOOGLE_ANALYTICS)
}
