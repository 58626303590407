import { Loans as LoanTypes } from '@miimosa/protocol'

import type { FrontCollectTypeType, FrontProjectStateType } from '../types'

export const collectTypesSwitch = {
  donation: 'donation' as const,
  lending: 'lending' as const,
  COLLECT_TYPE_DONATION: 'donation' as const,
  COLLECT_TYPE_LENDING: 'lending' as const,
  COLLECT_TYPE_UNSPECIFIED: 'donation' as const,
  COLLECT_TYPE_MERCHANT: 'merchant' as const,
  UNRECOGNIZED: 'donation' as const,
}

export const LOANS_STATUS_MAPPING = {
  wait: LoanTypes.LenderLoanSummariesRequestStatus.LENDER_LOAN_SUMMARIES_REQUEST_STATUS_WAITING_GENERATION,
  ok: LoanTypes.LenderLoanSummariesRequestStatus.LENDER_LOAN_SUMMARIES_REQUEST_STATUS_PROCESSING,
  late: LoanTypes.LenderLoanSummariesRequestStatus.LENDER_LOAN_SUMMARIES_REQUEST_STATUS_LATE,
  done: LoanTypes.LenderLoanSummariesRequestStatus.LENDER_LOAN_SUMMARIES_REQUEST_STATUS_TERMINATED,
}

export const getValidCollectType = (collectType?: FrontCollectTypeType, withUndefined = false) => {
  switch (collectTypesSwitch[collectType as keyof typeof collectTypesSwitch]) {
    case 'donation':
      return 'donation'
    case 'lending':
      return 'lending'
    default:
      return withUndefined ? undefined : 'donation'
  }
}

export const getValidProjectStateType = (projectStateType: FrontProjectStateType) => {
  switch (projectStateType) {
    case 'created':
      return 'created'
    case 'pending':
      return 'pending'
    case 'published':
      return 'published'
    case 'failed':
      return 'failed'
    case 'funded':
      return 'funded'
    case 'waiting_for_validation':
      return 'waiting_for_validation'
    case 'waiting_for_approval':
      return 'waiting_for_approval'
    default:
      return undefined
  }
}
