import { TFunction } from 'next-i18next'

export const getRewardKindCategories = (t?: TFunction) => {
  return [
    {
      slug: 'beehive_products',
      ...(t && { title: t('rewards_kind.categories.beehive_products', { ns: 'common' }) }),
    },
    {
      slug: 'celebration_beverages',
      ...(t && { title: t('rewards_kind.categories.celebration_beverages', { ns: 'common' }) }),
    },
    {
      slug: 'sweet_treats',
      ...(t && { title: t('rewards_kind.categories.sweet_treats', { ns: 'common' }) }),
    },
    {
      slug: 'salty_delicacies',
      ...(t && { title: t('rewards_kind.categories.salty_delicacies', { ns: 'common' }) }),
    },
    {
      slug: 'local_cheeses',
      ...(t && { title: t('rewards_kind.categories.local_cheeses', { ns: 'common' }) }),
    },
    {
      slug: 'special_plant_products',
      ...(t && { title: t('rewards_kind.categories.special_plant_products', { ns: 'common' }) }),
    },
    {
      slug: 'homemade_terrines',
      ...(t && { title: t('rewards_kind.categories.homemade_terrines', { ns: 'common' }) }),
    },
    {
      slug: 'sustainable_cosmetics',
      ...(t && { title: t('rewards_kind.categories.sustainable_cosmetics', { ns: 'common' }) }),
    },
  ]
}

export const getRewardKindIdBySlug = {
  beehive_products: 33,
  celebration_beverages: 34,
  sweet_treats: 35,
  salty_delicacies: 36,
  local_cheeses: 37,
  special_plant_products: 38,
  homemade_terrines: 39,
  sustainable_cosmetics: 40,
}
