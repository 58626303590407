// Adapter from https://github.com/filoxo/react-use-focus-exit/blob/master/src/index.js

import { FocusEvent, RefObject, useCallback } from 'react'

function useFocusExit<C extends HTMLElement>(
  containerRef: RefObject<C>,
  callback: (event: FocusEvent, inside: boolean) => void
): (event: FocusEvent) => void {
  return useCallback(
    (event) => {
      callback(
        event,
        event.relatedTarget ? containerRef?.current?.contains(event.relatedTarget as Node) == true : false
      )
    },
    [containerRef, callback]
  )
}

export default useFocusExit
